/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
'use client'

import Loader from '@components/common/loader'
import styles from './button.module.css'
import { type RouteType } from 'next/dist/lib/load-custom-routes'
import Link, { type LinkProps } from 'next/link'
import { type DetailedHTMLProps, type AnchorHTMLAttributes } from 'react'

type ButtonSize = 'sm' | 'md' | 'lg'

interface PlainButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'color'
  > {}

interface LinkButtonProps extends Omit<LinkProps<RouteType>, 'color'> {}

interface ExternalLinkButtonProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {
  external: boolean
}

export type V2ButtonProps = (
  | LinkButtonProps
  | PlainButtonProps
  | ExternalLinkButtonProps
) & {
  /**
   * Size of the button
   */
  size?: ButtonSize
  /**
   * Variant of the button
   */
  variant?: 'contained' | 'text' | 'outlined'
  /**
   * Colour of the button
   */
  colour?: 'primary' | 'secondary' | 'secondary-contrast' | 'white'
  /**
   * Should the button have the animations?
   */
  animate?: boolean
  /**
   * Show button full width
   */
  fullWidth?: boolean
  /**
   * Show loading state
   * @default false
   */
  loading?: boolean
  /**
   * Disable button
   * @default false
   */
  disabled?: boolean

  /**
   *
   * @returns
   */
  onClick?: () => void
}

const getSizeProps = (size: ButtonSize) => {
  switch (size) {
    case 'sm':
      return ` py-2 px-[18px] text-body3 ${styles.sm}`
    case 'md':
      return ` py-3 sm:py-[18px] px-8 sm:px-10 text-body2 sm:text-body1 ${styles.md}`
    case 'lg':
      return ` px-14 py-5 text-h8 ${styles.lg}`
  }
}

const Button: React.FC<V2ButtonProps> = ({
  size = 'md',
  className,
  variant = 'contained',
  colour = 'primary',
  animate = true,
  children,
  loading = false,
  disabled = false,
  fullWidth,
  ...props
}) => {
  let style = `flex items-center justify-center text-zinc-900 ${styles.button}`
  let styleBGColour = disabled ? 'bg-gray-500' : 'bg-white'
  let styleBoarderColour = disabled ? 'border-gray-500' : 'border-black'
  let spanStyle = ''

  switch (colour) {
    case 'primary':
      styleBGColour = disabled ? 'bg-gray-500' : 'bg-gold'
      styleBoarderColour = disabled
        ? 'border-gray-500'
        : variant === 'contained'
        ? 'border-gold'
        : 'border-black'
      spanStyle += ' after:border-l-black text-center w-fit'
      break
    case 'secondary':
      styleBGColour = disabled ? 'bg-gray-500' : 'bg-black'
      if (variant === 'contained') {
        spanStyle += ' text-white  after:border-l-white'
      } else if (variant === 'outlined') {
        spanStyle += ' after:border-l-black'
      } else {
        spanStyle += ' after:bg-black'
      }
      break
    case 'secondary-contrast':
      styleBoarderColour = disabled ? 'border-gray-500' : 'border-white'
      spanStyle += ' text-white '

      if (variant === 'contained') {
        spanStyle += ' after:border-l-white'
      } else if (variant === 'outlined') {
        spanStyle += ' after:border-l-white'
      } else {
        spanStyle += ' after:bg-white'
      }
      break
    case 'white':
      styleBoarderColour = disabled ? 'border-gray-500' : 'border-white'
      break
  }

  switch (variant) {
    case 'contained':
      style += ` ${styleBGColour} ${styleBoarderColour} border-2 border-solid font-semibold`
      break
    case 'outlined':
      style += ` ${styleBoarderColour} bg-transparent border-2 border-solid font-bold`
      break
    default:
      style += ` bg-transparent border-none after:bg-white font-medium`
      break
  }

  style += getSizeProps(size)

  if (animate && !loading && !disabled) {
    style += ` ${variant === 'text' ? styles.up : styles.tri}`
  }

  style += ` ${fullWidth ? 'w-full' : 'w-fit'}`

  if (className != null) {
    style += ` ${className}`
  }

  if ('external' in props) {
    const { external, ...newProps } = props
    return (
      <a className={style} {...newProps}>
        <span className={spanStyle}>{children}</span>
      </a>
    )
  }

  if ('href' in props) {
    return (
      <Link className={style} {...props}>
        <span className={spanStyle}>{children}</span>
      </Link>
    )
  }

  return (
    <button className={style} {...props} disabled={disabled}>
      <span className={spanStyle}>
        <div className='flex'>
          {loading && <Loader className='mr-3 h-8 w-8 fill-white' />}
          {children}
        </div>
      </span>
    </button>
  )
}

export default Button
