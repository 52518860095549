/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

/**
 * This function takes an object and reduces it to a form body string for POST requests
 * @param data
 * @returns
 */
export const reduceObjectToFormBody = (data: Record<string, any>) => {
  let formBody = ''
  for (const [key, value] of Object.entries(data)) {
    formBody += `&${key}=${encodeURIComponent(value ?? '')}`
  }
  // remove the first character '&' from the formBody
  return formBody.slice(1)
}
