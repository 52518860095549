'use client'

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { usePathname } from 'next/navigation'
import { useWindowSize } from '@components/hooks/use-window-size'

interface HeaderContextProps {
  isDark: boolean
  isOpen: boolean
  setOpen: (open: boolean) => void
  isSmall: boolean
}

const HeaderContext = createContext<HeaderContextProps>({
  isDark: false,
  isOpen: false,
  setOpen: () => {
    throw new Error('not ready yet')
  },
  isSmall: false,
})

export const useHeader = () => useContext(HeaderContext)

interface HeaderProviderProps {
  children: React.ReactNode
  darkRoutes: Set<string>
}

const HeaderProvider: React.FC<HeaderProviderProps> = ({
  children,
  darkRoutes,
}) => {
  const { width } = useWindowSize()
  const pathname = usePathname()
  const isDark = darkRoutes.has(pathname)

  const isSmall = (width ?? 0) <= 1000
  const [isOpen, setIsOpen] = useState(false)

  const setOpen = useCallback((open: boolean) => {
    const body = document.querySelector('body')

    if (open) {
      ;(body as HTMLBodyElement).style.overflow = 'hidden'
    } else {
      ;(body as HTMLBodyElement).style.removeProperty('overflow')
    }
    setIsOpen(open)
  }, [])

  const value = useMemo(
    () => ({
      isDark,
      isOpen,
      setOpen,
      isSmall,
    }),
    [isDark, isOpen, isSmall, setOpen],
  )

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}

export default HeaderProvider
