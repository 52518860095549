'use client'
import { useEffect } from 'react'

export default function Utm() {
  useEffect(() => {
    // Get UTM parameters
    const dhmUrlParams836 = new URLSearchParams(window.location.search)
    const dhmUtms836 = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      // 'dc',
      // 'utm_content',
    ]
    for (const element of dhmUtms836) {
      const utmEl = dhmUrlParams836.get(element)
      if (utmEl) {
        localStorage.setItem('dhm836_' + element, utmEl)
      }
    }
  }, [])
  return <></>
}
