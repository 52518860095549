'use client'

import Link from 'next/link'
import Logo from './logo'
import { useHeader } from './header-provider'

export default function HomeLink() {
  const { isDark, isOpen, setOpen } = useHeader()

  const closeMenu = () => {
    const menuBtn = document.querySelector<HTMLInputElement>('#menu-btn')
    if (menuBtn != null) {
      menuBtn.checked = false
    }
    if (isOpen) {
      setOpen(false)
    }
  }

  return (
    <Link
      className='fmy-2 mr-5 mt-2 block h-10  md:mr-10 lg:mr-20'
      onClick={closeMenu}
      href='/'
      aria-label='Home'
    >
      <Logo
        className={`transition-all duration-300 ${
          isDark && !isOpen ? 'fill-white' : 'fill-black'
        }`}
        width={256 / 3}
        height={114 / 3}
      />
    </Link>
  )
}
