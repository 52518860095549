import { type FC } from 'react'

interface Props {
  className?: string
  width?: number
  height?: number
}

const Logo: FC<Props> = ({ width = 256, height = 114, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    viewBox='0 0 256 114'
    aria-hidden='true'
  >
    <path d='M255.12 56.69C255.12 25.38 229.74 0 198.43 0C167.12 0 141.74 25.38 141.74 56.69H198.43L141.74 113.38H255.13V85.03H226.78L255.13 56.68L255.12 56.69Z' />
    <path d='M93.54 28.35L74.41 0H0L76.54 113.39L153.07 0H112.68L93.54 28.35Z' />
  </svg>
)

export default Logo
