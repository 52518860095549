import { cn } from '@lib/utils'

export default function Loader({
  className,
}: React.HTMLAttributes<SVGElement>) {
  return (
    <svg
      className={cn(className, 'animate-spin')}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='25 25 50 50'
      enableBackground='new 0 0 0 0'
      xmlSpace='preserve'
    >
      <path d='M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50' />
    </svg>
  )
}
